import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthencticationService {

  constructor(private http: HttpClient) { }
  base_Url: string = environment.base_Url;
  get<T>(url: string): Observable<T> {
    return this.http.get<T>(this.base_Url + url);
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(this.base_Url + url, body);
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.http.put<T>(this.base_Url + url, body);
  }

  patch<T>(url: string, body: any): Observable<T> {
    return this.http.patch<T>(this.base_Url + url, body);
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(this.base_Url + url);
  }
}
