import { Injectable } from '@angular/core';
import { AuthencticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(private httpService: AuthencticationService) { }

  resetPassword(data:any): any {
    return this.httpService.post(`user/reset_password`, data);
  }
  emailVerification(body:any):  any {
    return this.httpService.post(`user/verify_email_address`, body)
  }
}
